import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Divider,
  Chip,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TabBar from "../components/TabBar";
import MealDialog from "../components/MealDialog";
import { fetchMealHistory, deleteMealHistory } from "../services/mealService";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";
import DeleteConfirmationDialog from "../components/DeleteConfirmDialog";
import TitleBar from "../components/TitleBar";
import MealDetailDialog from "../components/MealDetailDialog";
import { MealHistory } from "../types/mealTypes";

const MealHistoryPage: React.FC = () => {
  const [mealHistory, setMealHistory] = useState<MealHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState<MealHistory | null>(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedMealDetail, setSelectedMealDetail] =
    useState<MealHistory | null>(null);
  const { userId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // 食事履歴をフェッチする関数をメモ化
  const fetchHistory = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchMealHistory(userId as string);
      if (response.success) {
        setMealHistory(response.mealHistory);
      } else {
        console.error("食事履歴の取得に失敗しました:", response.message);
      }
    } catch (error) {
      console.error("食事履歴の取得中にエラーが発生しました:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchHistory();
    }
  }, [userId, fetchHistory]);

  const handleAddMeal = () => {
    fetchHistory();
    enqueueSnackbar("食事履歴が追加されました", { variant: "success" });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchHistory();
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("ja-JP", options);
  };

  // 削除ダイアログを開く関数
  const handleDeleteClick = (meal: MealHistory) => {
    setSelectedMeal(meal);
    setDeleteOpen(true);
  };

  // 削除ダイアログを閉じる関数
  const handleDeleteClose = () => {
    setSelectedMeal(null);
    setDeleteOpen(false);
  };

  // 削除の確定処理（スナックバーを追加）
  const handleDeleteConfirm = async () => {
    if (selectedMeal && userId) {
      try {
        // 削除処理を実行するAPI呼び出し
        if (selectedMeal.meal_history_id) {
          await deleteMealHistory(
            selectedMeal.meal_history_id,
            userId,
            selectedMeal.is_image_input
          );
          setMealHistory(
            mealHistory.filter(
              (meal) => meal.meal_history_id !== selectedMeal.meal_history_id
            )
          );
          enqueueSnackbar("削除が成功しました", { variant: "success" });
        }
        setDeleteOpen(false);
      } catch (error) {
        enqueueSnackbar("削除に失敗しました", { variant: "error" });
        console.error("削除に失敗しました:", error);
      }
    }
  };

  const handleDetailOpen = (meal: MealHistory) => {
    setSelectedMealDetail(meal);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  return (
    <Box sx={{ pb: 8 }}>
      {/* TitleBar コンポーネント */}
      <TitleBar title="食事履歴" />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress size={40} sx={{ color: "#007BFF" }} />
        </Box>
      ) : mealHistory.length === 0 && !loading ? (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
          食事履歴がありません
        </Typography>
      ) : (
        <Grid container spacing={2} sx={{ p: 2 }}>
          {mealHistory.map((meal, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                onClick={() => handleDetailOpen(meal)}
                sx={{
                  // border: "1px solid rgba(204, 204, 204, 0.6)",
                  position: "relative",
                  borderRadius: "24px",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  height: "288px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: "12px",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(meal);
                    }}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      zIndex: 0,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 1)",
                      },
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <DeleteIcon sx={{ color: "rgba(99, 180, 255)" }} />
                  </IconButton>
                  <CardMedia
                    component="img"
                    image={meal.image_url || "/images/default_meal_image.jpg"}
                    alt="Meal Image"
                    sx={{
                      height: "160px",
                      width: "100%",
                      borderRadius: "16px",
                      objectFit: "cover",
                      aspectRatio: "16 / 9",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    height: "auto",
                    flex: 1,
                    p: "0px 12px 0px 12px",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: "1rem", fontWeight: "bold", mb: 0 }}
                  >
                    {meal.meal_name}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  {meal.meal_genre?.split(",").map((genre, index) => (
                    <Chip
                      key={index}
                      label={genre.trim()}
                      sx={{
                        fontSize: "0.8rem",
                        height: "20px",
                        mb: 0.5,
                        mr: 0.5,
                        backgroundColor: "rgba(99, 180, 255, 0.1)",
                        color: "rgba(99, 180, 255)",
                        "&:hover": {
                          backgroundColor: "rgba(99, 180, 255, 0.4)",
                        },
                      }}
                    />
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: 0.5,
                    }}
                  >
                    <IconButton sx={{ p: 0 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ ml: 1, color: "#999", fontSize: "0.85rem" }}
                    >
                      {formatDate(meal.meal_time)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      position: "absolute",
                      bottom: "8px",
                      right: "12px",
                      fontSize: "1.6rem",
                      color: "black",
                      mb: 1,
                      mr: 0.6,
                    }}
                  >
                    {meal.meal_calorie}kcal
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 88, right: 16 }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>

      <TabBar />
      <MealDialog open={open} onClose={handleClose} onSave={handleAddMeal} />
      {/* 削除ダイアログ */}
      <DeleteConfirmationDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        itemName={selectedMeal?.meal_name || "食事履歴"}
      />
      <MealDetailDialog
        open={detailOpen}
        onClose={handleDetailClose}
        meal={selectedMealDetail}
      />
    </Box>
  );
};

export default MealHistoryPage;
