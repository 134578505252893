export const addMealHistory = async (data: {
  imageFile?: File;
  mealType: string;
  mealTime: string;
  userId: string;
  mealName?: string;
  calories?: number;
  genres?: string[];
  reason?: string;
  is_image_input: boolean;
}) => {
  const formData = new FormData();
  formData.append("mealTime", data.mealTime);
  formData.append("mealType", data.mealType);
  formData.append("userId", data.userId);
  formData.append("is_image_input", data.is_image_input.toString());

  if (data.is_image_input && data.imageFile) {
    // 画像入力の場合
    formData.append("image", data.imageFile);
  } else {
    // 手動入力の場合
    if (data.mealName) {
      formData.append("mealName", data.mealName);
    }
    if (data.calories !== undefined) {
      formData.append("mealCalories", data.calories.toString());
    }
    if (data.genres) {
      formData.append("mealGenres", data.genres.join(","));
    }
    if (data.reason) {
      formData.append("mealReason", data.reason);
    }
  }

  const response = await fetch(
    "https://gourmet-recommender.noise06kmy69.workers.dev/meal-history/add",
    {
      method: "POST",
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("食事履歴の保存に失敗しました");
  }

  return await response.json();
};

export const fetchMealHistory = async (userId: string) => {
  try {
    const response = await fetch(
      `https://gourmet-recommender.noise06kmy69.workers.dev/meal-history?userId=${userId}`
    );

    if (!response.ok) {
      throw new Error("食事履歴の取得に失敗しました");
    }

    return await response.json();
  } catch (error) {
    console.error("食事履歴の取得エラー:", error);
    throw new Error("食事履歴の取得に失敗しました");
  }
};

export const deleteMealHistory = async (
  mealId: string,
  userId: string,
  isImageInput: boolean
) => {
  const response = await fetch(
    `https://gourmet-recommender.noise06kmy69.workers.dev/meal-history/delete`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mealId, userId, isImageInput }),
    }
  );

  if (!response.ok) {
    throw new Error("食事履歴の削除に失敗しました");
  }

  return await response.json();
};
