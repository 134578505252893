import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
  CircularProgress,
  Tab,
  Tabs,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { addMealHistory } from "../services/mealService";
import { useAuth } from "../contexts/AuthContext";

interface MealDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
}

const MealDialog: React.FC<MealDialogProps> = ({ open, onClose, onSave }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [mealTime, setMealTime] = useState<string>(
    new Date().toISOString().slice(0, 16)
  );
  const [activeTab, setActiveTab] = useState<number>(0);
  const [mealType, setMealType] = useState<string>("内食");
  const [mealName, setMealName] = useState<string>("");
  const [calories, setCalories] = useState<string>("");
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [reason, setReason] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const genres = [
    "日本料理",
    "洋食",
    "中華料理",
    "イタリア料理",
    "ハンバーガー",
    "カレー",
    "お好み焼き",
    "ラーメン",
    "うどん",
    "そば",
    "寿司",
    "天ぷら",
    "ステーキ",
    "しゃぶしゃぶ",
    "焼肉",
    "焼き鳥",
    "居酒屋",
    "フランス料理",
  ];

  const isSaveDisabled =
    activeTab === 0
      ? !(mealTime && selectedImage)
      : !(mealTime && mealName && calories && selectedGenres.length > 0); // 手入力タブでは全必須項目が揃っているか

  useEffect(() => {
    if (open) {
      const japanTime = new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 16);
      setMealTime(japanTime);
      resetInputs();
    }
  }, [open]);

  const resetInputs = () => {
    setImageSrc(null);
    setSelectedImage(null);
    setMealName("");
    setCalories("");
    setSelectedGenres([]);
    setReason("");
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setImageSrc(URL.createObjectURL(file));
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (userId) {
        if (activeTab === 0 && selectedImage) {
          // 画像入力
          await addMealHistory({
            mealType,
            imageFile: selectedImage,
            mealTime,
            userId,
            is_image_input: true,
          });
        } else if (
          activeTab === 1 &&
          mealType &&
          mealTime &&
          mealName &&
          calories &&
          selectedGenres.length > 0
        ) {
          // 手入力
          await addMealHistory({
            mealType,
            mealName,
            calories: parseInt(calories),
            genres: selectedGenres,
            mealTime,
            userId,
            reason,
            is_image_input: false,
          });
        } else {
          enqueueSnackbar("必要な項目をすべて入力してください", {
            variant: "error",
          });
          return;
        }

        onSave();
        enqueueSnackbar("食事履歴の追加に成功しました。", {
          variant: "success",
        });
        handleClose();
      } else {
        enqueueSnackbar("ユーザーIDが見つかりません。", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("食事履歴の追加に失敗しました。", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleClose = () => {
    setImageSrc(null);
    setSelectedImage(null);
    setMealTime(new Date().toISOString().slice(0, 16));
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.2rem",
          mt: 2,
        }}
      >
        食事履歴を追加
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#ccc",
            mt: 1,
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{ mt: -1 }}
        >
          <Tab label="画像入力" />
          <Tab label="手入力" />
        </Tabs>
        <Box sx={{ mb: 2 }} />
        {/* 画像入力タブ */}
        {activeTab === 0 && (
          <>
            <Typography sx={{ mb: 1 }}>食事日時</Typography>
            <TextField
              type="datetime-local"
              value={mealTime}
              onChange={(e) => setMealTime(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography sx={{ mb: 1 }}>食事タイプ</Typography>
            <TextField
              select
              value={mealType}
              onChange={(e) => setMealType(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <MenuItem value="内食">内食</MenuItem>
              <MenuItem value="外食">外食</MenuItem>
            </TextField>
            <Typography sx={{ mb: 1 }}>料理画像</Typography>
            <Box
              sx={{
                width: "100%",
                height: 200,
                border: "2px #999",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#f0f0f0", // デフォルトでグレー背景
                "&:hover": { backgroundColor: "#e0e0e0" }, // カーソルを合わせたときに少し暗くなる
                mb: 2,
              }}
              onClick={() => document.getElementById("imageInput")?.click()}
            >
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt="Selected"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <Typography>ここをクリックして画像を選択</Typography>
              )}
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                hidden
                onChange={handleImageChange}
              />
            </Box>
          </>
        )}

        {/* 手入力タブ */}
        {activeTab === 1 && (
          <>
            <Typography sx={{ mb: 1 }}>食事日時</Typography>
            <TextField
              type="datetime-local"
              value={mealTime}
              onChange={(e) => setMealTime(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography sx={{ mb: 1 }}>食事タイプ</Typography>
            <TextField
              select
              value={mealType}
              onChange={(e) => setMealType(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <MenuItem value="内食">内食</MenuItem>
              <MenuItem value="外食">外食</MenuItem>
            </TextField>
            <Typography sx={{ mb: 1 }}>料理名</Typography>
            <TextField
              value={mealName}
              onChange={(e) => setMealName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Typography sx={{ mb: 1 }}>カロリー (kcal)</Typography>
            <TextField
              type="number"
              value={calories}
              onChange={(e) => setCalories(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            {/* ジャンル選択 */}
            <Typography sx={{ mb: 1 }}>ジャンル (1つ選択)</Typography>
            <TextField
              select
              value={selectedGenres[0] || ""}
              onChange={(e) => setSelectedGenres([e.target.value])}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              {genres.map((genre) => (
                <MenuItem key={genre} value={genre}>
                  {genre}
                </MenuItem>
              ))}
            </TextField>
            <Typography sx={{ mb: 1 }}>食事概要（任意）</Typography>
            <TextField
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              fullWidth
              multiline
              rows={4}
              inputProps={{ maxLength: 300 }}
              sx={{ mb: 2 }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={loading}
          sx={{
            border: "1px solid #ccc",
            backgroundColor: "white",
            color: "#333",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          閉じる
        </Button>
        <Button
          onClick={handleSave}
          disabled={isSaveDisabled || loading}
          sx={{
            backgroundColor: isSaveDisabled ? "#ccc" : "#007BFF",
            color: "white",
            "&:hover": {
              backgroundColor: isSaveDisabled ? "#ccc" : "#0056b3",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "保存"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealDialog;
